<template>
  <div class="app-container">
    <h1>{{prod.title}}</h1>
    <div class="text">
      <div v-html="prod.text"></div>
    </div>
    <div class="divider"></div>
    <div class="gallary">
      <transition-group class="projects" name="bounce">
        <div class="project-item" v-for="(img, i) in images" :key="'gallary' + i">
          <div class="project">
            <v-img width="100%" class="prj-image" :src="img.path + img.img" @click="show(i)"></v-img>
          </div>
        </div>
      </transition-group>
    </div>
    <CoolLightBox 
      :items="imgs" 
      :index="index"
      @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data: () => ({
    index: null
  }),
  computed: {
    ...mapGetters(['getProdItem', 'getProd', 'getImages']),
    prod: function() {
      let id = localStorage.getItem('prodID')
      if(this.getProdItem.length != 0 && this.getProd.length != 0) {
        let title = this.getProdItem.find(p => p.id == id).prod
        let text = this.getProd.find(p => p.id == id).text
        return {title, text}
      } else {
        return {title: '', text: ''}
      }
    },
    images: function() {
      let id = localStorage.getItem('prodID')
      return this.getImages.filter(i => i.item_id == id)
    },
    imgs: function() {
      let id = localStorage.getItem('prodID')
      let arr = []
      this.getImages.filter(i => i.item_id == id).map(img => {
        arr.push(img.path + img.img)
      })
      return arr
    }
  },
  methods: {
    show(i) {
      this.index = i
    }
  },
  mounted() {
    window.scrollTo({top: 0})
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 2.1em;
    font-weight: 300;
    /* color: #8b0000; */
    line-height: 1;
    margin-bottom: 15px;
  }
  .divider {
    max-width: 350px;
    height: 3px;
    background-color: #505050;
    margin: 30px auto 0;
    border-radius: 3px;
  }
  .project-item {
    transition: all .35s ease-in-out;
  }

  .projects {
    margin-bottom:50px;
    margin-top:25px;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
  }
  .project {
    transition: all .35s ease-in-out;
    margin:10px;
    box-shadow:0px 2px 8px rgb(150, 150, 150);
    border-radius:3px;
    width:190px;
    height:190px;
    padding: 9px;
    display: flex;
    .prj-image {
      cursor: pointer;
    }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  @media only screen and (max-width: 735px) {
    .project {
      height: 150px;
      width: 150px;
    }
  }
  @media only screen and (max-width: 635px) {
    .project {
      height: 135px;
      width: 135px;
      padding: 6px;
    }
  }
  @media only screen and (max-width: 535px) {
    .project {
      height: 115px;
      width: 115px;
      padding: 5px;
    }
  }
  @media only screen and (max-width: 435px) {
    .project {
      height: 95px;
      width: 95px;
      padding: 3px;
    }
  }
  @media only screen and (max-width: 375px) {
    .project {
      height: 75px;
      width: 75px;
      padding: 3px;
    }
  }
</style>
